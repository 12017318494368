import React, { Fragment, useEffect, useMemo } from 'react'
import { navigate, PageProps } from 'gatsby'
import {
  Anchor,
  breakpoints,
  DefaultTheme,
  getStoredDistributionCenter,
  getStoredMarket,
  Market,
  useAuth,
  useLocation,
  useShoppingCart,
} from '@ecommerce/shared'
import styled, { css, ThemeProvider } from 'styled-components'
import loadable from '@loadable/component'
import { DynamicPage, PgPageProps } from '../../types/PgPages'
import { contentfulThemeToThemeExtension, LocationSelectorOption } from '../../graphql/global'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import { backgroundImageStyles } from '../../utils/styles'
import { Icon } from '../../components/Icon/Icon'
import { BlockType } from '../../graphql/contentfulTypes'
import Hero from '../../components/Moments/Hero'
import { useCartStockErrorHandler } from '../../utils/errors'
import { extractBlocksSkus } from '../utils'
import useAlgoliaFetch from '../../hooks/useAlgoliaFetch'
import Steps from '../../components/Steps'
import LocationSelector from '../../components/LocationSelector'
import withPageTransition from '../../components/withPageTransition'
import useSession from '../../hooks/useSession'
import ProductVideo from '../../components/ProductDetail/ProductVideo'
import { BannerSliderWidget } from '../../components/BannerSlider'
import { BannerFluidList } from '../../components/BannerFluid'
import slugFallback from '../../utils/slugFallback'
import { sendSelectItemEvent, sendPageViewEvent } from '../../utils/events'
import { generateProductUrl } from '../../config/siteBuild/utils'
import Promotions from '../home/Promotions'
import { SectionContainer } from '../home/styled'
import CountdownWidget from '../../components/Countdown/CountdownWidget'
import BrandsSliderWidget from '../../components/BrandsSlider/BrandsSliderWidget'
import { Content } from '../../components/Content'

const ProductSlidesList = loadable(() => import('../../components/Moments/ProductSlidesList'))
const PromosGrid = loadable(() => import('../../components/Moments/PromosGrid'))
const FilteredProductList = loadable(() => import('../../components/Moments/FilteredProductList'), { ssr: false })
const ProductsList = loadable(() => import('../home/SkuList'), { ssr: false })
const RecipeList = loadable(() => import('../../components/Recipes/RecipesList'))
const NewsList = loadable(() => import('../../components/News/NewsList'))
const VideosList = loadable(() => import('../../components/Moments/VideosList'))
const BrandsList = loadable(() => import('../../components/Moments/BrandsList'))
const HowItWorks = loadable(() => import('../../components/Moments/HowItWorks'))

type Props = PageProps<{}, { data: DynamicPage } & PgPageProps['pageContext']>

type AnchorWrapperProps = {
  hasAnchor: boolean
}

const TemplateWrapper = styled.div<{ backgroundImage?: string }>`
  background: ${({ theme }) => theme.colors.background.body};
  color: ${({ theme }) => theme.colors.bodyText};
  ${backgroundImageStyles()}
  .MomentProductList {
    padding-bottom: 30px;
  }
`

const AnchorWrapper = styled.div<AnchorWrapperProps>`
  max-width: 1500px;
  margin: auto;
  display: none;
  padding: 20px 65px;

  .go-back {
    display: flex;
  }

  @media (${breakpoints.desktop.min}) {
    display: block;

    ${({ hasAnchor }) => !hasAnchor && `padding: 39px 65px;`}
  }
`

const Section = styled.div<{ paddingTop?: boolean }>`
  margin-top: 40px;

  h2 {
    text-align: center;
  }

  p {
    text-align: left;
  }

  ${({ paddingTop }) =>
    paddingTop &&
    css`
      @media (${breakpoints.desktop.min}) {
        padding-top: 56px;
      }
    `}
`

const DynamicPageTemplate = ({ pageContext }: Props) => {
  const storedCity = getStoredMarket()
  const { isBolivia } = useLocation()

  const {
    data: { title, slug, description, keywords = [], blocks, theme: pageTheme, navbar },
    markets,
    productCategories,
    currentDistributionCenter,
    currentMarket,
  } = pageContext
  const storedDistributionCenter = getStoredDistributionCenter() || currentDistributionCenter
  const incomingCity = getStoredMarket() || currentMarket

  const { getConnectifCart } = useShoppingCart()
  const { getEntityInfo } = useAuth()

  const themeExtension = pageTheme ? contentfulThemeToThemeExtension(pageTheme) : {}
  const theme = (currentTheme: DefaultTheme) => ({
    ...currentTheme,
    colors: { ...currentTheme.colors, ...themeExtension },
  })
  const { resetAppState } = useSession()

  const stockErrorHandler = useCartStockErrorHandler()
  const themeColor = pageTheme?.brandDefault

  const skus = useMemo(() => extractBlocksSkus(blocks), [])
  const { products, loadingProducts } = useAlgoliaFetch({
    distributionCenter: storedDistributionCenter,
    skus,
    navigationState: {
      themeId: pageTheme?.contentful_id,
    },
  })

  const onSelect = async (option: LocationSelectorOption) => {
    const newCity = markets.find((c) => c.slug === option.slug) || markets[0] || ({} as Market)
    await resetAppState(`/${slug}`, {
      keepCart: true,
      keepAuth: true,
      newMarket: newCity,
    })
  }

  useEffect(() => {
    const entityInfo = getEntityInfo()
    const cart = getConnectifCart()
    sendPageViewEvent({ cart, entityInfo, event: 'SingleDynamicPage', title })
  }, [])

  useEffect(() => {
    if (incomingCity && storedCity && incomingCity.slug !== storedCity.slug) {
      resetAppState(`/${slug}`, { keepCart: true, keepAuth: true, newMarket: incomingCity })
    }
  }, [])

  if (!incomingCity?.slug && storedCity?.slug) {
    navigate(`/${slug}`)
    return null
  }

  return (
    <ThemeProvider theme={theme}>
      <Layout
        title={title}
        navbarType={navbar?.type}
        navbarTitlte={navbar?.title}
        categories={productCategories}
        disableControls={!incomingCity?.slug}
      >
        <Seo keywords={keywords} description={description}>
          {themeColor && <meta name="theme-color" content={themeColor} />}
        </Seo>
        <TemplateWrapper backgroundImage={pageTheme?.bodyBackgroundImage?.file?.url}>
          {pageContext?.data?.navbar && (
            <AnchorWrapper hasAnchor={!!pageContext?.data?.navbar}>
              <Anchor onClick={() => navigate(`/`)} className="go-back">
                <Icon iconId="arrow_left" size="15" />
                <span>Volver al inicio</span>
              </Anchor>
            </AnchorWrapper>
          )}
          {blocks?.map((block, index) => {
            const { contentful_id, name } = block
            const hasSearchboxPadding = index > 0 && blocks[index - 1].blockType === BlockType.BANNER_SLIDER
            const background = block.background?.url

            switch (block.blockType) {
              case BlockType.BANNER_SLIDER: {
                return (
                  <Fragment key={name}>
                    <BannerSliderWidget listId={contentful_id} />
                  </Fragment>
                )
              }

              case BlockType.BANNER_FLUID: {
                return <BannerFluidList key={block.contentful_id} listId={contentful_id} />
              }

              case BlockType.TEXT_WITH_ICON: {
                return (
                  <Section key={block.contentful_id}>
                    <Steps listId={block.contentful_id} backgroundColor={block.backgroundColor} />
                  </Section>
                )
              }

              case BlockType.HERO: {
                return (
                  <Section paddingTop={!!block.paddingTop}>
                    <Hero key={block.contentful_id} listId={block.contentful_id} />
                  </Section>
                )
              }

              case BlockType.SINGLE_VIDEO: {
                return (
                  <Section>
                    <ProductVideo key={block.contentful_id} altBackground listId={block.contentful_id} />
                  </Section>
                )
              }

              case BlockType.PRODUCT_SLIDE: {
                return (
                  <ProductSlidesList
                    key={block.contentful_id}
                    listId={block.contentful_id}
                    themeId={pageTheme?.contentful_id}
                  />
                )
              }

              case BlockType.PROMO_XL_GRID: {
                return <PromosGrid key={block.contentful_id} listId={block.contentful_id} />
              }

              case BlockType.PROMO_LARGE:
              case BlockType.PROMO_SMALL: {
                return (
                  <SectionContainer
                    hasSearchboxPadding={hasSearchboxPadding}
                    key={name}
                    className="promos-container"
                    background={background}
                    hasAltBackground
                  >
                    <Promotions showRibbon={false} listId={contentful_id} />
                  </SectionContainer>
                )
              }

              case BlockType.FILTERED_LIST: {
                return (
                  <FilteredProductList
                    key={contentful_id}
                    listId={contentful_id}
                    themeId={pageTheme?.contentful_id}
                    onCardClick={({ product, listName, themeId }) => {
                      sendSelectItemEvent(product, listName, isBolivia())
                      navigate(`/products/${generateProductUrl(product.title, product.skuCode)}`, {
                        state: { themeId, originListName: listName },
                      })
                    }}
                  />
                )
              }

              case BlockType.RECIPE: {
                return (
                  <RecipeList
                    {...block}
                    key={block.contentful_id}
                    listId={block.contentful_id}
                    actionSlug={block.actionSlug ? `${slugFallback(block.actionSlug)}` : undefined}
                  />
                )
              }

              case BlockType.BRANDS_LIST: {
                return <BrandsList listId={block.contentful_id} key={block.contentful_id} />
              }

              case BlockType.BRAND: {
                return <BrandsSliderWidget key={contentful_id} listId={contentful_id} />
              }

              case BlockType.PRODUCT: {
                return (
                  <ProductsList
                    pageProducts={products}
                    loadingProducts={loadingProducts}
                    key={block.contentful_id}
                    styleProps={{ bgTop: 'transparent' }}
                    stockErrorHandler={stockErrorHandler}
                    listId={block.contentful_id}
                    className="MomentProductList"
                    useBackground
                  />
                )
              }

              case BlockType.VIDEO: {
                return <VideosList listId={block.contentful_id} key={block.contentful_id} />
              }

              case BlockType.NEWS: {
                return <NewsList key={block.contentful_id} listId={block.contentful_id} />
              }

              case BlockType.HOW_IT_WORKS: {
                return <HowItWorks key={block.contentful_id} listId={block.contentful_id} />
              }

              case BlockType.COUNTDOWN: {
                return <CountdownWidget listId={contentful_id} key={name} />
              }

              case BlockType.CONTENT: {
                return <Content key={block.contentful_id} listId={block.contentful_id} />
              }

              default:
                return null
            }
          })}
          <br />
        </TemplateWrapper>
        {!incomingCity?.slug && (
          <LocationSelector onClose={() => null} hasCloseButton={false} excludeExternal onSelect={onSelect} />
        )}
      </Layout>
    </ThemeProvider>
  )
}

export default withPageTransition(DynamicPageTemplate)
