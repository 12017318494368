import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { INLINES } from '@contentful/rich-text-types'
import { Link } from 'gatsby'
import useQuery from '../../hooks/useQuery'
import ContentQuery, { QueryContentResult } from '../../graphql/contentQuery'
import { Container, Wrapper } from '../StaticPages'

interface Props {
  listId: string
}

export const Content = ({ listId }: Props) => {
  const { data } = useQuery<QueryContentResult>(ContentQuery, { variables: { listId } })

  const orList = data?.orList
  const content = orList?.itemsCollection.items[0].content

  if (!content) return null

  return (
    <Wrapper>
      <Container>
        {content?.json
          ? documentToReactComponents(content.json, {
              renderNode: {
                [INLINES.ASSET_HYPERLINK]: (node, contentData) => {
                  const url = node?.data?.target?.fields?.file.es.url

                  return (
                    <Link to={url ?? '#'} target="_blank" rel="noopener noreferrer">
                      {contentData}
                    </Link>
                  )
                },
              },
            })
          : null}
      </Container>
    </Wrapper>
  )
}
